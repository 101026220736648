import { useState, useEffect } from "react";
import {
  UploadFiles,
  createNote,
  deleteNote,
  downloadFile,
  getNote,
  updateNote,
} from "api/note";
import { saveAs } from "file-saver";
import { FiX } from "react-icons/fi";
import { CgSpinnerTwo } from "react-icons/cg";
import useApp from "hooks/useApp";

export interface IFile {
  fileName: string;
  file: any;
}

const NoteForm: any = ({
  noteDetails,
  noteId,
  closeHandler,
  getNotes,
  //updateNotes,
}: any) => {
  const [message, setMessage] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [values, setValues] = useState<any>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [fileDownload, setFile] = useState<Blob | null>(null);
  const [fileDownloadName, setFileName] = useState("");
  const [loading, setLoading] = useState(true);
  const [submittingForm, setSubmittingForm] = useState(false);
  const { userRole } = useApp();
  const [header, setHeader] = useState<any>("");
  const [note, setNote] = useState<any>("");

  function SmallLoader() {
    return <CgSpinnerTwo size={35} className="animate-spin text-green" />;
  }

  const b64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: any[] = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const _byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(_byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const editMode = Boolean(noteId);
  const acceptedFormats = [
    "application/pdf",
    ".pdf",
  ];

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (editMode) {
        const note = await getNote(noteId);
        setValues({
          ...values,
          id: note.data.id,
          files: note.data.files,
        });
        setHeader(note.data.header);
        setNote(note.data.note);
      }
    })();
    setLoading(false);
  }, [noteId]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles([...e.target.files]);
    }
  };

  const NoteHandler = async (e: any) => {
    setSubmittingForm(true);
    e.preventDefault();
    if (editMode) {
      const data: any = {
        id: 0,
        header: "",
        note: "",
        fileIds: []
      };
      data.id = values.id;
      data.header = header;
      data.note = note;

      const formData = new FormData();
      formData.append("id", values.id);
      formData.append("header", header);
      if (note) formData.append("note", note);
      if (files) {
        const tempFormData = new FormData();
        files.map((file, index) => {
          tempFormData.append(`file ${index}`, file);
        });
        const response = await UploadFiles(tempFormData);
        if (response.status === 200) {
          const allfiles = response.data;
          const allids: any = [];
          allfiles.map((file: any) => {
            allids.push(file.uniqueId);
          });

          formData.append("fileIds", allids);
          data.fileIds = allids;
        }
      }
      await updateNote(data);
      //updateNotes();
      setSubmittingForm(false);
    }
    else {
      const data: any = {
        header: "",
        note: "",
        x: 0,
        y: 0,
        z: 0,
        fileIds: []
      };

      const formData = new FormData();
      formData.append("header", header);
      data.header = header;
      if (note) formData.append("note", note);
      data.note = note;
      formData.append("x", noteDetails.x);
      data.x = noteDetails.x;
      data.y = noteDetails.y;
      data.z = noteDetails.z;
      formData.append("y", noteDetails.y);
      formData.append("z", noteDetails.z);
      if (files) {
        const tempFormData = new FormData();
        files.map((file, index) => {
          tempFormData.append(`file ${index}`, file);
        });
        const response = await UploadFiles(tempFormData);
        if (response.status === 200) {
          const allfiles = response.data;
          const allids: any = [];
          allfiles.map((file: any) => {
            allids.push(file.uniqueId);
          });

          formData.append("fileIds", allids);
          data.fileIds = allids;
        }
      }

      await createNote(data);
      //updateNotes();
      setSubmittingForm(false);
    }
    getNotes();
    closeHandler();
  };

  const deleteHandler = async () => {
    setSubmittingForm(true);
    if (noteId) {
      const text =
        "Closing the window will delete the Note. Are you sure you want to close ?";
      if (window.confirm(text) === true) {
        await deleteNote(noteId);
        setValues(null);
        //updateNotes();
        getNotes();
      }
    }
    closeHandler();
  };


  const deleteFileHandler = (uniqueId: any) => {
    setValues((state: any) => {
      if (state?.files) {
        const files = state?.files.filter(
          (file: any) => file.uniqueId !== uniqueId
        );
        return { ...state, files: files };
      }
      return state;
    });
    //updateNotes();
  };

  useEffect(() => {
    if (fileDownload && fileDownloadName) {
      const blob = b64toBlob(fileDownload);
      saveAs(blob, fileDownloadName);
      setMessage(["File downloaded successfully"]);
    }
  }, [fileDownload, fileDownloadName]);

  const downloadFileHandler = async (id: string) => {
    await downloadFile(id).then(
      (r: any) => {
        if (r.data.file) {
          setError(false);
          setFile(r.data.file);
          setFileName(r.data.fileName);
        } else if (r.data.error) {
          setError(true);
          setMessage([r.data.error]);
        }
      },
      () => {
        console.error("error");
      }
    );
  };

  return (
    <div className="fixed inset-0 z-10 w-full h-screen mt-8">
      <div className="absolute z-20 inset-0 w-full h-full bg-black/70" />

      <div className="w-full h-auto gradient p-0.5 rounded-md z-30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[650px]">
        <div className="bg-dark p-3 w-full h-full rounded-md">
          {loading && (
            <div className="w-full h-[300px] grid place-content-center">
              <SmallLoader />
            </div>
          )}
          {!loading && (
            <>
              {/* Header */}
              <div className="flex justify-between">
                <h1 className="text-2xl font-bold text-green">Note</h1>
                <div
                  className="bg-green p-1 rounded-md cursor-pointer grid place-content-center"
                  onClick={closeHandler}
                >
                  <FiX size={20} />
                </div>
              </div>
              {/* Content */}
              <div className="mt-5">
                {message.map((eachMessage, index) => (
                  <div className="pt-4" key={`ticket_${index}`}>
                    <p className={error ? "text-danger" : "text-success"}>
                      {eachMessage}
                    </p>
                  </div>
                ))}
                <form className="mt-3" onSubmit={NoteHandler}>
                  <h1 className="text-lg text-center font-semibold">
                    {editMode ? noteDetails?.header : "New Note"}
                  </h1>
                  <div className="mt-3">
                    <label htmlFor="header">Header</label>
                    <textarea
                      key="header"
                      id="header"
                      name="header"
                      placeholder="Header"
                      rows={1}
                      required={true}
                      className="input mt-1 resize-none"
                      value={header}
                      onChange={(e: any) => setHeader(e.target.value)}
                    />
                  </div>
                  <div className="mt-3">
                    <label htmlFor="note">Note</label>
                    <textarea
                      rows={5}
                      key="note"
                      id="note"
                      name="note"
                      placeholder="Note"
                      className="input mt-1 max-h-[330px]"
                      value={note}
                      onChange={(e: any) => setNote(e.target.value)}
                    />
                  </div>
                  {!!values?.files?.length && (
                    <div className="my-3">
                      <label className="text-green text-lg">
                        Attached Files
                      </label>
                      {values?.files?.map((file: any) => (
                        <div
                          className="flex items-center my-1 gap-2"
                          key={file.uniqueId}
                        >
                          <div>
                            <span
                              className="underline cursor-pointer"
                              onClick={() => downloadFileHandler(file.uniqueId)}
                            >
                              {file.fileName}
                            </span>
                          </div>
                          <div
                            onClick={() => deleteFileHandler(file.uniqueId)}
                            className="underline cursor-pointer"
                          >
                            <FiX />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="flex flex-col mt-5">
                    <label htmlFor="name">Files</label>
                    <input
                      type="file"
                      multiple={true}
                      name="Notes_pdf"
                      onChange={handleFileChange}
                      className="input"
                      accept={acceptedFormats.join(",")}
                    />
                  </div>
                  <div className="flex flex-row-reverse mt-5 gap-5">
                    {userRole === 'admin' && <input
                      name="create"
                      id="create"
                      key="create"
                      value={editMode ? "Update" : "Create"}
                      type="submit"
                      className="btn btn-fill btn-primary"
                      disabled={submittingForm}
                    />}
                    {editMode && userRole === 'admin' && (
                      <input
                        name="delete"
                        id="delete"
                        key="delete"
                        value="Delete"
                        type="button"
                        onClick={deleteHandler}
                        className="btn"
                      />
                    )}
                    {submittingForm && <SmallLoader />}
                  </div>
                </form>
              </div>
            </>
          )}
          {/* Content end */}
        </div>
      </div>
    </div>
  );
};
export default NoteForm;
