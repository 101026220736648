import { useCallback, useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { SplashScreen } from "./SplashScreen";
import Note from "./note";
import { getAllNotes } from "api/note";
import useApp from "hooks/useApp";
import useScreen from "hooks/useScreen";
import classNames from "utilities/ClassNames";

const UnityWP = () => {
  const [shownote, setshownote] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [unityPrefabLoading, setUnityPrefabLoading] = useState(0);
  const [unityLoadingProgress, setUnityLoadingProgress] = useState(0);
  const [fakeLoading, setFakeLoading] = useState(false);
  const { user } = useApp();
  const { isScaled } = useScreen();

  const [loadingStatus, setLoadingStatus] = useState(
    "Loading data from the network"
  );

  const {
    unityProvider,
    sendMessage,
    isLoaded,
    addEventListener,
    removeEventListener,
  } = useUnityContext({
    loaderUrl: "Build/Unity/WebGL.loader.js",
    dataUrl: "Build/Unity/WebGL.data",
    frameworkUrl: "Build/Unity/WebGL.framework.js",
    codeUrl: "Build/Unity/WebGL.wasm",
    streamingAssetsUrl: "Build/Unity/StreamingAssets",
  });

  useEffect(() => {
    if (isLoaded) {
      setLoadingStatus(
        "We are building your digital twin, it will take from 30 seconds till 4 min depends on graphic card"
      );
    }
  }, [isLoaded]);

  function unityLoadingInterval() {
    const intervalId = setInterval(() => {
      setUnityLoadingProgress((prevProgress) => {
        if (prevProgress !== 99) {
          return prevProgress + 1;
        } else {
          clearInterval(intervalId);
          return prevProgress;
        }
      });
    }, 3000);
  }

  useEffect(() => {
    if (!fakeLoading) {
      if (unityPrefabLoading < 90) {
        setUnityLoadingProgress(unityPrefabLoading);
      } else if (unityPrefabLoading === 90) {
        setUnityLoadingProgress(90);
        unityLoadingInterval();
        setFakeLoading(true);
      }
    }
  }, [unityPrefabLoading]);


  const noteCloseHandler = useCallback(() => {
    setshownote(false);
  }, []);

  const createNote: any = useCallback((x: number, y: number, z: number) => {
    const noteDetails = { x, y, z };
    setNotePopup(noteDetails);
    setNoteId(null);
    setshownote(true);
  }, []);

  const unityProgressBarHandler: any = useCallback((amount: number) => {
    const loadingAmount = Math.round(90 * amount);
    setUnityPrefabLoading(loadingAmount);
  }, []);

  const showunity = () => {
    setShowLogo(false);
  };
  const openNote: any = useCallback((noteId: number) => {
    setNotePopup(null);
    setNoteId(noteId);
    setshownote(true);
  }, []);

  useEffect(() => {
    if (!showLogo) {
      sendMessage("UserDetails", "setUserDetails", JSON.stringify(user));
    }
  }, [showLogo]);

  useEffect(() => {
    addEventListener("NewNoteAdded", createNote);
    addEventListener("UnityIsReady", showunity);
    addEventListener("openNote", openNote);
    addEventListener("unityProgressBar", unityProgressBarHandler);
    return () => {
      removeEventListener("NewNoteAdded", createNote);
      removeEventListener("UnityIsReady", showunity);
      removeEventListener("unityProgressBar", unityProgressBarHandler);
    };
  }, [addEventListener, removeEventListener, createNote, showunity, unityProgressBarHandler]);

  const [notePopup, setNotePopup] = useState<any>(null);
  const [popupNoteId, setNoteId] = useState<number | null>(null);
  const noteModalOpen = false;
  const [allNotes, setAllNotes] = useState<any>(null);

  const getData = async () => {
    const response = await getAllNotes();
    if (response.status === 200) {
      setAllNotes(response.data);
    }
  };

  useEffect(() => {
    sendMessage("MainNote", "SetNotesList", JSON.stringify(allNotes));
  }, [allNotes]);

  useEffect(() => {
    if (!showLogo) {
      getData();
    }
  }, [showLogo]);

  useEffect(() => {
    const handleUnload = () => {
      window.location.reload();
    };
    window.addEventListener("unload", handleUnload);
    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  return (
    <div className={classNames(isScaled ? "top-12" : "top-16", "absolute bottom-0 right-0 left-0")}>
      {shownote && (
        <Note
          noteId={popupNoteId}
          modalOpen={noteModalOpen}
          noteDetails={notePopup}
          closeHandler={noteCloseHandler}
          getNotes={getData}
        />
      )}
      <div id="unity-container" className={`w-full h-full`}>
        <SplashScreen
          open={showLogo}
          logo={true}
          status={loadingStatus}
          isScaled={isScaled}
          progressStatus={unityLoadingProgress}
        />
        <Unity
          unityProvider={unityProvider}
          className="unityApp"
          style={{
            width: "100%",
            height: "100%",
            visibility: isLoaded ? "visible" : "hidden",
          }}
          devicePixelRatio={isScaled ? 1.308 : 1}
        />
      </div>
    </div>
  );
};
export default UnityWP;
